import EventBus from './event-bus'

export default {    
    name: 'ShowOrder',    
  
    methods: {
      showOrderForm() {        
        EventBus.$emit('show-order-form');
      },
    },  
    
  }
  