<template>
  <base-section id="services">
    <base-section-heading
      color="grey lighten-2"      
      title="Услуги и цены"
    />

    <v-container>
      <v-responsive
        class="mx-auto align-center text-center"
        max-width="800"
      >
        <v-row class="align-center">
          <v-col>
            <div class="text-subtitle-1">
              Стоимость услуг и список документов, необходимых для подачи заявки на получение СБКТС и ЭПТС вы можете узнать, оставив заявку. Наши сотрудники свяжутся с вами в кротчайшие сроки.      
            </div>
            <base-btn min-width="25%" class="my-10" @click="showOrderForm">
              Оставить заявку
            </base-btn>
            <div>
              <base-subheading title="Описание схемы сертификации:" />
              <ol class="text-left">        
                <li>Подача заявки от заказчика</li>
                <li>Анализ первичной документации, полученной от заказчика</li>
                <li>Назначение времени и подача ТС в испытательную лабораторию для прохождения всех необходимых испытаний (измерений) для подтверждения соответствия техническому регламенту ТР ТС Таможенного союза 018/2011 “О безопасности колесных транспортных средств”.</li>
                <li>Получение выписки и оригинала СБКТС и ЭПТС</li>
              </ol>
            </div>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </base-section>
</template>

<script>
  import ShowOrder from '@/mixins/show-order'

  export default {
    name: 'SectionServices',

    mixins: [ShowOrder],

    data: () => ({
      
    }),
  }
</script>
